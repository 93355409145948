
import React, { useState } from "react";
import "./LandingPage.css";
import { Helmet } from 'react-helmet'; // For managing SEO meta tags
import hero1 from "../assets/hero1.webp"
import hero2 from "../assets/hero 2.webp"
import { Link, useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';



const LandingPage = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

   


    const features = [
        {
            title: "Affordable Pricing",
            description: "Get the most cost-effective restaurant billing software without compromising on quality.",
            icon: "💸"
        },
        {
            title: "Quick & Easy Setup",
            description: "Start using Artha POS within minutes with its user-friendly interface and hassle-free setup.",
            icon: "⚡"
        },
        {
            title: "Streamlined Billing",
            description: "Simplify your restaurant billing with fast and accurate invoicing tailored to your needs.",
            icon: "🧾"
        },

        {
            title: "Payment Reports",
            description: "Keep track of all payment transactions in real-time with detailed and organized reports.",
            icon: "💳",
        },
        {
            title: "Order Reports",
            description: "Generate comprehensive order reports to monitor sales trends and improve customer service.",
            icon: "📦",
        },
        {
            title: "Vendor Dashboard",
            description: "Manage and interact with your vendors through an intuitive dashboard for smooth operations.",
            icon: "📋",
        },
        {
            title: "24/7 Support",
            description: "Enjoy round-the-clock assistance to ensure your restaurant operations run smoothly.",
            icon: "📞"
        },
    ];
    const features1 = [
        {
            title: "Recipe Management & Costing",
            description: "Effortlessly manage your recipes and calculate costs to maximize your restaurant's profitability.",
            icon: "🍔",
        },
        {
            title: "Purchase Management",
            description: "Track and streamline your purchases with our easy-to-use system, ensuring smooth operations.",
            icon: "💲",
        },
        {
            title: "Stock Management & Inventory",
            description: "Monitor and control your stock levels in real-time to minimize wastage and optimize inventory.",
            icon: "📊",
        },
        {
            title: "Powerful Inventory Reporting",
            description: "Gain valuable insights with detailed inventory reports to make data-driven decisions effortlessly.",
            icon: "📈",
        },
        {
            title: "Payment Reports",
            description: "Keep track of all payment transactions in real-time with detailed and organized reports.",
            icon: "💳",
        },
    ];

    const plans = [
        {
            name: "Basic",
            price: "₹1500",
            period: "/3Months",
            features: ["Up to 1 Outlet", "5 Users", "unlimited Devices Devices", "Free Support"],
        },
        {
            name: "Super",
            price: "₹3799",
            period: "/6Months",
            features: ["Up to 1 Outlet", "10 Users", "Unlimited Devices", "Free Support"],
        },
        {
            name: "Premium",
            price: "₹6999",
            period: "/12Months",
            features: ["Up to 1 Outlet", "10 Users", "Unlimited Devices", "Free Support"],
        },

    ];
    const testimonials = [
        {
            name: 'Nicodemus Zorah',
            text: 'Lorem Ipsum is simply dummy text of the printing and typesetting indusssimply dummy.',
            role: 'UX/UI Designer',
            image: 'https://via.placeholder.com/100', // Replace with actual image URLs
        },
        {
            name: 'Nicodemus Zorah',
            text: 'Lorem Ipsum is simply dummy text of the printing and typesetting indusssimply dummy.',
            role: 'UX/UI Designer',
            image: 'https://via.placeholder.com/100', // Replace with actual image URLs
        },
        {
            name: 'Nicodemus Zorah',
            text: 'Lorem Ipsum is simply dummy text of the printing and typesetting indusssimply dummy.',
            role: 'UX/UI Designer',
            image: 'https://via.placeholder.com/100', // Replace with actual image URLs
        },
    ];
    const handleLogin = () => {
        navigate('/login');
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
    
        // Collect form data
        const form = e.target;
    
        // Send the form data using EmailJS
        emailjs
          .sendForm(
            "service_zxj451j", // Replace with your Service ID
            "template_8lw0xrh", // Replace with your Template ID
            form,
            "TTawii7rmPoVPXWod" // Replace with your Public Key
          )
          .then(
            (result) => {
              alert("Your request has been sent successfully!");
              form.reset(); // Reset form fields
              handleCloseModal(); // Close modal after submission
            },
            (error) => {
              alert("There was an error sending your request. Please try again.");
              console.error("Error:", error);
            }
          );
      };
    

    return (
        <div className="page-wrapper">

        <div className="app">
        <div className="content">
            <Helmet>
                <title>Restaurant Billing Software | Affordable & Easy - Artha</title>
                <meta
                    name="description"
                    content="Looking for affordable restaurant billing software? Try Artha – the easiest and cheapest solution for restaurant billing. Manage orders, inventory, and more!"
                />
                <meta
                    name="keywords"
                    content="restaurant billing software, Artha, cheapest restaurant billing software, Artha billing software,
                          affordable billing solutions, restaurant management software,Petpooja alternative,Best Petpooja competitors in India,Online billing software for cafes,POS software for small restaurants, billing software for cafes
                          Restaurant billing software better than Petpooja,Restaurant software like Petpooja,AI-powered restaurant billing software
                            ,Best billing software for quick-service restaurants,Free demo of restaurant billing software,Restaurant billing software with kitchen order tracking,
                                    Best POS billing software for fast food outlets,Best restaurant billing software for small businesses,Restaurant billing software in India,Best restaurant billing software in Pune,Best restaurant billing software in Latur,Table management system for restaurants,
                                    Multi-branch restaurant billing system,Best restaurant management software,Restaurant POS software,Billing software for restaurants,Affordable billing software for restaurants,"
                />
                <meta name="author" content="Artha Team" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="google-site-verification" content="lyosIQ6jkJXyxtxtv3PVOKfxl4D_BdU3Zef32o4nlBI" />
            </Helmet>
            {/* Header Section */}
            <header className="header">
                <div className="logo">Artha</div>
                <nav className="nav">
                    <a href="#home">Home</a>
                    <a href="#services">Services</a>
                    <a href="#features">Page Features</a>
                    <a href="#pricing">Pricing</a>
                    <a href="#contact">Contact Us</a>
                    <a href="#blog">Blog</a>
                </nav>
                <div className="header-buttons">
                    <button className="login-btn" onClick={handleLogin}>Login</button>
                    <button className="cta-btn" onClick={handleOpenModal}>Try For Free</button>
                    {isModalOpen && (
                        <div className="modal-overlay1">
                            <div className="modal-content1">
                                <button className="close-button" onClick={handleCloseModal}>
                                    &times;
                                </button>
                                <h2>Request a Demo</h2>
                                <form className="demo-form" onSubmit={handleFormSubmit}>
                                    <input type="text" name="name" placeholder="Enter your name" required />
                                    <input type="text" name="bname" placeholder="Enter your Buiseness name" required />

                                    <input type="tel" name="contact_number" placeholder="Enter your contact number" required />
                                    <input type="email" name="email" placeholder="Enter your email" required />
                                    <button type="submit">Submit</button>
                                </form>
                            </div>
                        </div>
                    )}
                </div>
            </header>


            {/* Hero Section */}
            <section id="home" className="hero">
                <div className="hero-text">
                    <h1>
                        Simplify your restaurant and retail operations with <span>Artha POS</span>.
                    </h1>
                    <p>
                        Artha POS is your all-in-one solution for managing billing, inventory, and customer engagement.
                        Trusted by thousands of businesses, we help you streamline operations and boost profitability.
                    </p>
                    <button className="cta-btn">Explore Features</button>
                </div>
                <div className="hero-image">
                    <img
                        src={hero1} // Replace with your image URL
                        alt="Tablet with POS system"
                    />
                </div>
            </section>
            <div  id="services" className="feature-section">
                <div className="feature-header">
                    <h2>#Our Services</h2>
                    <p>Discover what we can do to enhance your business experience.</p>
                </div>
                <div className="features-grid">
                    {features.map((feature, index) => (
                        <div className="feature-card" key={index}>
                            <div className="feature-icon">{feature.icon}</div>
                            <h3>{feature.title}</h3>
                            <p>{feature.description}</p>
                        </div>
                    ))}
                </div>
                <div id="features"className="inventory-section">
                    <div className="inventory-header">
                        <h2>
                            Simplify Your <span>#Restaurant Operations</span>
                        </h2>
                        <p>
                            Experience seamless management with India's most affordable restaurant billing and inventory software, designed for your success.
                        </p>
                    </div>
                    <div className="inventory-grid">
                        {features1.map((feature, index) => (
                            <div className="inventory-card" key={index}>
                                <div className="inventory-icon">{feature.icon}</div>
                                <h3>{feature.title}</h3>
                                <p>{feature.description}</p>
                            </div>
                        ))}
                    </div>

                </div>
                <section  id="contact"class="promo">
                    <div class="promo-container">
                        <div class="promo-text">
                            <h1>
                                Revolutionize Your Restaurant with
                                <span>#India's Cheapest Billing Software</span>
                            </h1>
                            <p>
                                Streamline your restaurant operations with our all-in-one software. Manage billing, orders, inventory, and more with ease and affordability. Perfect for restaurants, cafes, and food trucks.
                            </p>
                            <button class="promo-button" onClick={handleOpenModal}>Get Free Trial</button>
                        </div>
                        <div class="promo-image">
                            <img src={hero2} alt="Team collaboration">
                            </img>
                        </div>
                    </div>
                </section>
                <section  id="blog" class="testimonials">
                    <div class="testimonial-summary">
                        <h4>Testimonials</h4>
                        <h2>What Our Clients Say About Us</h2>
                        <p>
                            Thousands of restaurant owners across India trust our software to streamline their operations and boost profitability. Join them and transform your business today!
                        </p>
                    </div>
                    <div  class="container">

                        <div class="testimonials-grid">

                            <div class="testimonial-card">
                                <img src="path-to-image-1.jpg" alt="Client 1" class="client-image" />
                                <h3>Ravi Sharma</h3>
                                <p>
                                    This software has completely transformed our restaurant operations! Easy to use and saves so much time.
                                </p>
                                <p class="designation">Restaurant Owner</p>
                                <a href="#" class="share-link">Share</a>
                            </div>


                            <div class="testimonial-card">
                                <img src="path-to-image-2.jpg" alt="Client 2" class="client-image" />
                                <h3>Pooja Patel</h3>
                                <p>
                                    The billing process has become effortless. Highly affordable and packed with features!
                                </p>
                                <p class="designation">Cafe Manager</p>
                                <a href="#" class="share-link">Share</a>
                            </div>

                            <div class="testimonial-card center-card">
                                <img src="path-to-image-3.jpg" alt="Client 3" class="client-image" />
                                <h3>Arjun Verma</h3>
                                <p>
                                    Best investment for my restaurant. The inventory and order management tools are a game-changer!
                                </p>
                                <p class="designation">Food Truck Owner</p>
                                <a href="#" class="share-link">Share</a>
                            </div>
                        </div>
                    </div>
                </section>





                <div id="pricing" className="pricing-table-container">
                    <h2 className="pricing-title">#Pricing Table</h2>
                    <p className="pricing-description">
                        Morbi sit amet dapibus sapien. Orci varius natoque magnis dis parturient montes, nascetur.
                    </p>
                    <div className="pricing-cards">
                        {plans.map((plan, index) => (
                            <div className="pricing-card" key={index}>
                                <div className="pricing-icon">
                                    <i className="fas fa-money-bag"></i>
                                </div>
                                <h3 className="pricing-name">{plan.name}</h3>
                                <ul className="pricing-features">
                                    {plan.features.map((feature, idx) => (
                                        <li key={idx} className="pricing-feature">
                                            <i className="fas fa-check-circle"></i> {feature}
                                        </li>
                                    ))}
                                </ul>
                                <div className="pricing-price">
                                    <span className="price">{plan.price}</span>
                                    <span className="period">{plan.period}</span>
                                </div>
                                <button className="buy-now-button" onClick={handleOpenModal}>Buy Now</button>
                            </div>
                        ))}
                    </div>
                </div>
                </div>
                <footer className="footer">
                    <div className="footer-container">
                        <div className="footer-logo-section">
                            <p className="footer-description">
                                artha  adress one phase one sant tukaram nagar dehu road
                            </p>
                            <div className="footer-contact">
                                <p>
                                    <i className="fas fa-envelope"></i> artha2376@gmail.com
                                </p>
                                <p>
                                    <i className="fas fa-globe"></i> artha.in.net
                                </p>
                            </div>
                            <div className="footer-social-icons">
                                <i className="fab fa-facebook-f"></i>
                                <i className="fab fa-twitter"></i>
                                <i className="fab fa-linkedin-in"></i>
                                <i className="fab fa-pinterest-p"></i>
                            </div>
                        </div>

                        <div className="footer-links">
                            <div className="footer-column">
                                <h3>POS Categories</h3>
                                <ul>
                                    <li>Restaurant</li>
                                    <li>Cafe</li>
                                    <li>Foodtruck</li>
                                    <li>Franchise</li>
                                    <li>Retail</li>
                                    <li>Barbershop</li>
                                    <li>Bakery</li>
                                    <li>Popup-Store</li>
                                </ul>
                            </div>

                            <div className="footer-column">
                                <h3>Language</h3>
                                <ul>
                                    <li>English</li>
                                    <li>Hindi</li>
                                </ul>
                            </div>

                            <div className="footer-column">
                                <h3>Customers</h3>
                                <ul>
                                    <li>Offline POS</li>
                                    <li>Office Login</li>
                                    <li>Customer Support</li>
                                    <li>Monthly Payment</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <p>Copyright © All Rights Reserved 24webpro 2022.</p>
                    </div>
                </footer>



            </div>
        </div>
        </div>
    );
};

export default LandingPage;
